import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled, { ThemeProvider } from 'styled-components';
import GlobalStyle from '../theme/GlobalStyle';
import { Theme } from '../theme/Theme';
import Menu from '../components/Menu';
import MenuToggle from '../components/MenuToggle';
import MenuMobile from '../components/MenuMobile';
import Logo from '../components/Logo';
import IndexPage from '../pages';
import { useEffect, useState } from 'react';
import ChangeLanguage from '../components/molecules/ChangeLanguage'
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { setActiveLanguage } from '../store/menuSlice';

const StyledLayoutWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  display: grid;
  /* overflow: hidden; */
  grid-template-columns: 5fr 2fr;
  overflow:hidden;
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;

const StyledContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  scroll-behavior: smooth;
  overflow-x: hidden;
  /* position: relative; */
  &.one-page {
    overflow-y: hidden;
    @media (max-width: 1200px) {
      overflow-y: auto;
    }
  }
`;

const MobileBlend = styled.div`
  width: 100%;
  position: fixed;
  height: 100px;
  top: 0;
  backdrop-filter: blur(50px);
  background-color: rgba(255, 255, 255, 0.7);
  display: none;
  z-index: 102;

  @media (max-width: 1200px) {
    display: block;
  }
`;

const Line = styled.div`
  width: 5px;
  height: 20vh;
  background-color: ${({ theme }) => theme.colors.blue};
  position: absolute;
display:block;
  z-index: 9999999;
  left:0;
  transition:all 0.5s ease-in-out;

  &.home{
    height: 2vh;
    top: 0vh;
    background-color: ${({ theme }) => theme.colors.red};
  }
  &.about{
    height: 20vh;
    top: 0vh;
    background-color: ${({ theme }) => theme.colors.blue};
  }
  &.why-us{
    height: 20vh;
    background-color: ${({ theme }) => theme.colors.yellow};
    top: 20vh;
  }
  &.what-make-us-stand-out{
    height: 20vh;
    background-color: ${({ theme }) => theme.colors.red};
  top: 40vh;
  }
  &.factor-x{
    height: 20vh;
    background-color: ${({ theme }) => theme.colors.purple};
    top: 60vh;
  }
  &.lets-start{
    height: 20vh;
    background-color: ${({ theme }) => theme.colors.blue};
    top: 80vh;
  }

  @media (max-width:1200px){
    display: none;
  }

`;


const TextBottom = styled.p`
  font-family: 'Staatliches';
  /* display: block; */
  position: fixed;
  font-size: 200px;
  /* line-height: 130px; */
  bottom: -60px;
    left: -9px;
 
  color: black;
  width: 100%;
  transition: all 0.3 ease-in-out;
  display: inline-block;
  width: 71.8vw;
  overflow:hidden;
  z-index: 501;
  @media (max-width: 1440px) {
    font-size: 141px;
    line-height: 124px;
    bottom: -16px;
    width: 72.1vw;
  }
  @media (max-width: 1200px) {
    bottom: -13px;
    color: white;
    width: 100%;
    left: 0;
    display: none;
  }
  @media (max-width: 550px) {

  }

  &:hover {
  
    /* transform: matrix(-1, 0, 0, 1, 0, 0); */
    color: transparent;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: black;
    @media (max-width: 1200px) {
      -webkit-text-stroke-color: white;
    }
    @media (max-width: 550px) {
      -webkit-text-stroke-color: black;
    }
  }
`;


const MenuLineWrapper = styled.div`
position: relative;
width:auto;`

interface Props {
  children: React.ReactNode;
}

const Layout: React.FC<Props> = ({ children }) => {

  const activeTextBottom = useAppSelector((state) => state.menu.activeTextBottom);
  const activeTextBottomHover = useAppSelector((state) => state.menu.activeTextBottomHover);
  const scrollPosition = useAppSelector((state) => state.menu.scrollPosition);
  const activeLanguage = useAppSelector((state) => state.menu.activeLanguage)

  const [finalTextBottom, setFinalTextBottom] = useState<string>(activeTextBottom)

  useEffect(() => {
    setFinalTextBottom(activeTextBottom)
  }, [activeTextBottom])

  const dataQuery = useStaticQuery(graphql`
    query menuQuery  {
      datoCmsMenuModule(locale: {eq: "en"}) {
      whyUsLink
      whatMakeUsStandOutLink
      letsStartLink
      factorXLink
      aboutLink
      facebookUrl
      logoDescription
      linkedinUrl
      instagramUrl
      
    }
    datoCmsAboutModule(locale: {eq: "en"}) {
    textBottomHover
    textBottom
  }
  datoCmsFactorXModule(locale: {eq: "en"}) {
    textBottomHover
    textBottom
  }
  datoCmsWhatMakeUsStandOutModule(locale: {eq: "en"}) {
    textBottomHover
    textBottom
  }
  datoCmsWhyUsModule(locale: {eq: "en"}) {
    textBottomHover
    textBottom
  }
    }
    
  `);







  const menuData = dataQuery || '';



  return (
    <div>
      <ThemeProvider theme={Theme}>
        <GlobalStyle />
        <StyledLayoutWrapper>
          <StyledContentWrapper className="one-page">
            {children}
          </StyledContentWrapper>
          <ChangeLanguage language='language'/>
          <MenuToggle text='open' />
          <MenuMobile data={menuData} />
          <Logo data={menuData} />
          <MobileBlend />
          <MenuLineWrapper>
            <Line className={scrollPosition} />
            <Menu data={menuData} />
          </MenuLineWrapper>
          <TextBottom onMouseEnter={() => setFinalTextBottom(activeTextBottomHover)} onMouseLeave={() => setFinalTextBottom(activeTextBottom)}>{finalTextBottom.replace(/ /g, '')}</TextBottom>
        </StyledLayoutWrapper>
      </ThemeProvider>
    </div>
  );
};

export default Layout;
